import React, { Component } from 'react'
import { Hide } from '@elparking/components'
import PropTypes from 'prop-types'

import { DATE_FORMAT } from 'commons/js/constants'

import DesktopDateTimeSelect from './DesktopDateTimeSelect'
import MobileDateTimeSelect from './MobileDateTimeSelect'

class DateTimeSelect extends Component {
    static propTypes = {
        /* eslint-disable react/no-unused-prop-types */
        value: PropTypes.instanceOf(Date),

        format: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
        ]),

        dayPickerProps: PropTypes.object,
        timePickerProps: PropTypes.shape({
            value: PropTypes.instanceOf(Date),
            disabledHours: PropTypes.object,
            onChange: PropTypes.func,
        }),
        hideOnDayClick: PropTypes.bool,
        clickUnselectsDay: PropTypes.bool,
        component: PropTypes.any,

        className: PropTypes.string,

        onChange: PropTypes.func,
        onClick: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onKeyUp: PropTypes.func,
        onNext: PropTypes.func,

        showTimePicker: PropTypes.bool,
        showMonthPicker: PropTypes.bool,
        prevYears: PropTypes.number,
        nextYears: PropTypes.number,
        showNextButton: PropTypes.bool,
        /* eslint-enable react/no-unused-prop-types */
    }

    static defaultProps = {
        format: DATE_FORMAT,
        showNextButton: false,
        dayPickerProps: {
            disabledDays: [],
        },
        timePickerProps: {},
        onNext: () => {},
    }

    focus () {
        // TODO: Focus either desktop or mobile, but no both
        this.dateTimeSelect.focus()
        this.dateTimeSelectMobile.focus()
    }

    blur () {
        this.dateTimeSelect.blur()
    }

    render () {
        const { className, showNextButton, onNext, ...restProps } = this.props
        return (
            <div className={className}>
                <Hide xs sm>
                    <DesktopDateTimeSelect
                      ref={(c) => (this.dateTimeSelect = c)}
                      showNextButton={showNextButton}
                      onNext={onNext}
                      {...restProps}
                    />
                </Hide>
                <Hide md lg>
                    <MobileDateTimeSelect
                      ref={(c) => (this.dateTimeSelectMobile = c)}
                      {...restProps}
                    />
                </Hide>
            </div>
        )
    }
}

export default DateTimeSelect
