import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex, Box, Copy, Dropdown, Icon, themeColor } from '@elparking/components'

import {
    MERIDIEM_FORMAT,
    HOUR_24_FORMAT,
    HOUR_12_FORMAT,
    MINUTES_FORMAT,
} from 'commons/js/constants'
import { formatDate, mergeDate } from 'commons/js/util/dateConverter'

import { buildHours, buildMinutes, buildMeridiems } from './buildTime'

import Picker from './Picker'

export const ArrowBlockContainer = styled.div`
    position: relative;
    height: 56px;
    width: 1em;
`
export const ArrowBlock = styled(Flex)`
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 56px;
    width: 64px;
    background: ${themeColor('main')};
    align-items: center;
    justify-content: center;
`

class TimePicker extends React.PureComponent {
    static propTypes = {
        value: PropTypes.instanceOf(Date),
        showMeridiem: PropTypes.bool,
        disabledHours: PropTypes.object,
        onChange: PropTypes.func,
        onNext: PropTypes.func,
        showNextButton: PropTypes.bool,
    }

    static defaultProps = {
        showNextButton: false,
        showMeridiem: false,
        onChange: () => {},
        onNext: () => {},
    }

    handleChange = this.handleChange.bind(this)

    handleChange (value, name) {
        const nameMap = {
            hour: 'hour',
            minute: 'minute',
            meridiem: 'hour',
        }

        this.props.onChange(mergeDate(this.props.value, { [nameMap[name]]: parseInt(value, 10) }))
    }

    buildOptions (dates, displayFormat, format) {
        return dates.map((date) => ({
            label: formatDate(date, displayFormat),
            value: formatDate(date, format),
        }))
    }

    renderUnavailable () {
        return (
            <Flex justifyContent='space-between' alignItems='center' width='100%'>
                <Box flex='0 0 auto' mr='1em'>
                    <img src='/images/watch.svg' />
                </Box>
                <Flex flex='1 1 auto' justifyContent='center' alignItems='center' mr='1em'>
                    <Picker>
                        <Box m='0.5em 0'>--</Box>
                    </Picker>
                    <Box mx='0.5em'>
                        <Copy padding='0'>:</Copy>
                    </Box>
                    <Picker>
                        <Box m='0.5em 0'>--</Box>
                    </Picker>
                </Flex>
            </Flex>
        )
    }

    renderMeridiem () {
        const { value, disabledHours } = this.props

        const selectedMeridiem = formatDate(value, HOUR_24_FORMAT)
        const meridiems = buildMeridiems(value, disabledHours)
        const meridiemOptions = this.buildOptions(meridiems, MERIDIEM_FORMAT, HOUR_24_FORMAT)

        return (
            <Box flex='1 0 auto' p='0 1em 0 0'>
                <Dropdown
                  dataTest='timepicker-meridiem'
                  renderPlaceholder={() => '--'}
                  focusOnClick={false}
                  selectorElement={Picker}
                  options={meridiemOptions}
                  onChange={({value}) => this.handleChange(value, 'meridiem')}
                  defaultValue={selectedMeridiem}
                  value={selectedMeridiem}
                />
            </Box>
        )
    }

    renderArrow () {
        return (
            <Box flex='1 0 auto'>
                <ArrowBlockContainer >
                    <ArrowBlock onClick={this.props.onNext} >
                        <Icon type='right' color='white' size='large' />
                    </ArrowBlock>
                </ArrowBlockContainer>
            </Box>
        )
    }

    render () {
        const { value, disabledHours, showMeridiem, showNextButton } = this.props

        if (!value) {
            return this.renderUnavailable()
        }

        const hoursFormat = showMeridiem ? HOUR_12_FORMAT : HOUR_24_FORMAT
        const minutesFormat = MINUTES_FORMAT
        const hours = buildHours(value, disabledHours, showMeridiem)
        const minutes = buildMinutes(value, disabledHours)
        const selectedHour = formatDate(value, HOUR_24_FORMAT)
        const selectedMinute = formatDate(value, minutesFormat)
        const hourOptions = this.buildOptions(hours, hoursFormat, HOUR_24_FORMAT)
        const minuteOptions = this.buildOptions(minutes, minutesFormat, MINUTES_FORMAT)

        return (
            <Flex justifyContent='space-between' alignItems='center' width='100%'>
                <Box flex='0 0 auto' mr='1em'>
                    <img src='/images/watch.svg' />
                </Box>
                <Flex flex='1 1 auto' justifyContent='center' alignItems='center' mr='1em'>
                    <Dropdown
                      dataTest='timepicker-hour'
                      renderPlaceholder={() => '--'}
                      focusOnClick={false}
                      selectorElement={Picker}
                      options={hourOptions}
                      onChange={({value}) => this.handleChange(value, 'hour')}
                      value={selectedHour}
                      defaultValue={selectedHour}
                    />
                    <Box mx='0.5em'>
                        <Copy padding='0'>:</Copy>
                    </Box>
                    <Dropdown
                      dataTest='timepicker-minute'
                      renderPlaceholder={() => '--'}
                      focusOnClick={false}
                      selectorElement={Picker}
                      options={minuteOptions}
                      onChange={({value}) => this.handleChange(value, 'minute')}
                      value={selectedMinute}
                      defaultValue={selectedMinute}
                    />
                </Flex>
                {showMeridiem && this.renderMeridiem()}
                {showNextButton && this.renderArrow()}
            </Flex>
        )
    }
}

export default TimePicker
