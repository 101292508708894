import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Dropdown } from '@elparking/components'
import { mergeDate } from 'commons/js/util/dateConverter'

class YearMonthForm extends React.PureComponent {
    static propTypes = {
        date: PropTypes.instanceOf(Date),
        localeUtils: PropTypes.object,
        classNames: PropTypes.shape({
            caption: PropTypes.string.isRequired,
        }).isRequired,
        onChange: PropTypes.func,
        nextYears: PropTypes.number,
        prevYears: PropTypes.number,
        locale: PropTypes.string,
    }

    handleChange = this.handleChange.bind(this)

    handleChange (name, value) {
        this.props.onChange(mergeDate(this.props.date, { [name]: value }))
    }

    calculateMonths = this.calculateMonths.bind(this)

    calculateMonths ({ localeUtils }) {
        return localeUtils.getMonths(this.props.locale)
    }

    calculateYears ({ prevYears, nextYears }) {
        const years = []
        const fromMonth = new Date(new Date().getFullYear(), 0)
        const startYear = parseInt(fromMonth.getFullYear()) - prevYears
        const endYear = parseInt(fromMonth.getFullYear()) + nextYears

        for (let i = startYear; i <= endYear; i += 1) {
            years.push(i)
        }

        return years
    }

    render () {
        const { date, localeUtils, nextYears, prevYears, classNames } = this.props

        const months = this.calculateMonths({ localeUtils })
        const years = this.calculateYears({ prevYears, nextYears })

        const month = date.getMonth()
        const year = date.getFullYear()

        return (
            <div className={classNames.caption}>
                <Flex>
                    <Box w='50%'>
                        <Dropdown
                          focusOnClick={false}
                          options={months.map((label, v) => ({label, value: `${v}`}))}
                          onChange={({value}) => this.handleChange('month', value)}
                          defaultValue={month || '0'} />
                    </Box>
                    <Box w='50%'>
                        <Dropdown
                          focusOnClick={false}
                          options={years.map((label, v) => ({label, value: `${v}`}))}
                          onChange={({label}) => this.handleChange('year', label)}
                          defaultValue={`${years.indexOf(year) || '0'}`} />
                    </Box>
                </Flex>
            </div>
        )
    }
}

export default YearMonthForm
