import styled from 'styled-components'
import { transparentize } from 'polished'

import { themeColor, themeFontWeight } from '@elparking/components'
import DateTimeSelect from './DateTimeSelect'

const accentColor = (props) => transparentize(0.6, themeColor('accent')(props))

// TODO: Wrap Overlay instead of DateTimeSelect
export default styled(DateTimeSelect)`
    color: ${themeColor('gray')};
    font-weight: ${themeFontWeight('regular')};

    border: 2px solid ${themeColor('lightGray40')};
    border-radius: 5px;

    padding-left: 1em;

    display: flex;
    align-items: center;

    & > div {
        width: 100%;
    }

    .DayPickerInput {
        display: block;
    }

    .DayPicker-wrapper {
        outline: none;
    }

    .DayPicker-Caption {
        margin-bottom: 1em;
        font-weight: ${themeFontWeight('book')};
        text-transform: uppercase;
        text-align: center;
    }

    .DayPicker-NavButton--prev {
        left: 1.5em;
    }

    .DayPicker-NavButton--next {

    }

    .DayPicker-NavButton {
        width: 1em;
        height: 1em;
        outline: none;
    }

    .DayPicker-Weekday abbr[title] {
        border-bottom: none;
        text-decoration: none;
    }

    .DayPicker-Weekday {
        font-weight: ${themeFontWeight('book')};
        color: ${themeColor('gray')};
    }

    .DayPicker-Day {
        border: 0;
        outline: none;
    }

    .DayPicker-Day--today {
        color: inherit;
        font-weight: ${themeFontWeight('regular')};
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        position: relative;
        color: inherit;
        background-color: ${themeColor('accent')};
        border-radius: 0;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
        background-color: ${themeColor('accent')};
        border-radius: 0;
    }

    .DayPicker:not(.DayPicker--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color: ${accentColor};
        border-radius: 0;
    }

    .DayPicker-Day--disabled {
        color: ${themeColor('gray20')};
        background-color: inherit;
        cursor: not-allowed;
    }

    .DayPicker-Day--outside {
        color: ${themeColor('gray20')};
        cursor: default;
    }
`
