import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { themeColor, Copy } from '@elparking/components'
import { formatDay, parseMoment } from 'commons/js/util/dateConverter'

const DateTimeInputWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`

const DateTimeLabel = styled.label`
    display: block;
    border: 0;
    width: 100%;
    height: 100%;
    padding: 1em;
    cursor: pointer;
    user-select: none;
`

const DateTimeInput = styled.input`
    border: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
    user-select: none;
    pointer-events: none;

    &:focus + ${DateTimeLabel} {
        color: ${themeColor('main')};
    }
`

const nextUniqueId = ((counter = 0) => () => counter++)()

class Input extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string,
        format: PropTypes.string,
        placeholder: PropTypes.string,
    }

    dateTimeId = `dateTimeInput_${nextUniqueId()}`

    focus = this.focus.bind(this)

    focus () {
        this.input.focus()
    }

    blur () {
        this.input.blur()
    }

    setInputRef = this.setInputRef.bind(this)

    setInputRef (element) {
        this.input = element
    }

    renderDay (value) {
        if (!value) {
            return (
                <Copy as='span' className='DayPickerInputPlaceholder' padding='0' color='gray60' fontWeight='regular' size='small'>
                    {this.props.placeholder}
                </Copy>
            )
        }

        return (
            <Copy as='span' className='DayPickerInputValue' padding='0' color='gray' fontWeight='medium' size='small'>
                {formatDay(value, this.props.format)}
            </Copy>
        )
    }

    render () {
        let day

        if (this.props.value) {
            day = parseMoment(this.props.value)
        }

        return (
            <DateTimeInputWrapper>
                <DateTimeInput {...this.props} id={this.dateTimeId} ref={this.setInputRef} readOnly />
                <DateTimeLabel htmlFor={this.dateTimeId}>
                    {this.renderDay(day)}
                </DateTimeLabel>
            </DateTimeInputWrapper>
        )
    }
}

export default Input
