import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'

import TimePicker from '../TimePicker'
import YearMonthPicker from '../YearMonthPicker'

import { themeColor } from '@elparking/components'

const grayColor = (props) => transparentize(0.6, themeColor('gray')(props))

const StyledOverlay = styled.div`
    border: 1px solid ${themeColor('lightGray40')};
    box-shadow: 2px 4px 4px 0 ${grayColor};

    /* Made with http://www.cssarrowplease.com/ */

    &:after,
    &:before {
        bottom: 100%;
        left: 1rem;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: transparent;
        border-bottom-color: ${themeColor('white')};
        border-width: 10px;
        margin-left: -10px;
    }

    &:before {
        border-color: transparent;
        border-bottom-color: ${themeColor('lightGray40')};
        border-width: 11px;
        margin-left: -11px;
    }

    .DayPicker {
        display: block;
    }
`

const TimePickerWrapper = styled.div`
    padding: 1em;
    border-top: 1px solid ${themeColor('lightGray40')};
`

class Overlay extends React.PureComponent {
    static propTypes = {
        children: PropTypes.element,
        classNames: PropTypes.object,
        selectedDay: PropTypes.instanceOf(Date),
        month: PropTypes.instanceOf(Date),
        input: PropTypes.any,
        locale: PropTypes.string,
        showTimePicker: PropTypes.bool,
        showYearMonthPicker: PropTypes.bool,
        timePickerProps: PropTypes.object,
        yearMonthPickerProps: PropTypes.object,
        showNextButton: PropTypes.bool,
        onNext: PropTypes.func,
    }

    static defaultProps = {
        showTimePicker: true,
        showYearMonthPicker: true,
        timePickerProps: {},
        yearMonthPickerProps: {},
        showNextButton: false,
        onNext: () => {},
    }

    renderCaption = this.renderCaption.bind(this)

    renderCaption (props) {
        return <YearMonthPicker {...props} {...this.props.yearMonthPickerProps} />
    }

    render () {
        const {
            children,
            classNames,
            selectedDay,
            month,
            input,
            showTimePicker,
            showYearMonthPicker,
            timePickerProps,
            yearMonthPickerProps,
            showNextButton,
            onNext,
            ...htmlProps
        } = this.props

        return (
            <div className={classNames.overlayWrapper} {...htmlProps}>
                <StyledOverlay className={classNames.overlay}>
                    {React.cloneElement(children, { captionElement: showYearMonthPicker ? this.renderCaption : void 0 })}
                    {showTimePicker &&
                        <TimePickerWrapper>
                            <TimePicker
                              {...timePickerProps}
                              showNextButton={showNextButton}
                              value={selectedDay}
                              onNext={onNext}
                            />
                        </TimePickerWrapper>}
                </StyledOverlay>
            </div>
        )
    }
}

export default Overlay
