import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
    themeColor,
 Copy } from '@elparking/components'

import { parseMoment, formatDay } from 'commons/js/util/dateConverter'

import {
    DATE_FORMAT,
    DATETIME_FORMAT,
    DATETIME_FORMAT_WITH_SECONDS,
    DATETIME_FORMAT_WITH_MILLISECONDS,
    LOCALE_DATE_FORMAT,
} from 'commons/js/constants'

const DateTimeInputWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`

const DateTimeLabel = styled.label`
    display: block;
    border: 0;
    width: 100%;
    height: 100%;
    padding: 0.5em 1em;
`

const DateTimeInput = styled.input`
    border: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;

    &:focus + ${DateTimeLabel} > ${Copy} {
        color: ${themeColor('main')};
    }
`

const DATETIME_LOCAL_PARSE_FORMATS = [DATETIME_FORMAT, DATETIME_FORMAT_WITH_SECONDS, DATETIME_FORMAT_WITH_MILLISECONDS]
const DATE_PARSE_FORMATS = [DATE_FORMAT]

const getInputParseFormats = (showTimePicker) => showTimePicker ? DATETIME_LOCAL_PARSE_FORMATS : DATE_PARSE_FORMATS
const getInputFormat = (showTimePicker) => showTimePicker ? DATETIME_FORMAT : DATE_FORMAT
const getInputType = (showTimePicker) => showTimePicker ? 'datetime-local' : 'date'

const nextUniqueId = ((counter = 0) => () => counter++)()

function getMinimumRangeModifier (disabledDays) {
    const min = Math.min(...disabledDays.map(({ before } = {}) => before).filter((date) => !!date))
    if (min === Infinity) {
        return null
    }
    return min
}

function getMaximunRangeModifier (disabledDays) {
    const max = Math.max(...disabledDays.map(({ after } = {}) => after).filter((date) => !!date))
    if (max === -Infinity) {
        return null
    }
    return max
}

class MobileDateTimeSelect extends Component {
    static propTypes = {
        value: PropTypes.instanceOf(Date),
        format: PropTypes.string,
        placeholder: PropTypes.string,
        dayPickerProps: PropTypes.object,
        showTimePicker: PropTypes.bool,
        onChange: PropTypes.func,
    }

    static defaultProps = {
        format: LOCALE_DATE_FORMAT,
        placeholder: 'Enter a date',
        showTimePicker: true,
        onChange: () => {},
    };

    constructor (props) {
        super(props)

        this.dateTimeId = `dateTimeInput_${nextUniqueId()}`
        this.input = null
        this.onChange = this.onChange.bind(this)
    }

    onChange (event) {
        const value = event.target.value

        if (!value) {
            return
        }

        const m = parseMoment(value, getInputParseFormats(this.props.showTimePicker))

        this.props.onChange(m)
    }

    focus () {
        this.input && this.input.focus()
    }

    blur () {
        this.input && this.input.blur()
    }

    renderDay (day) {
        if (!day) {
            return (
                <Copy as='span' color='gray60' fontWeight='regular' size='small'>
                    {this.props.placeholder}
                </Copy>
            )
        }

        return (
            <Copy as='span' color='gray' fontWeight='book' size='medium'>
                {formatDay(day, this.props.format)}
            </Copy>
        )
    }

    render () {
        let day

        const { dayPickerProps } = this.props
        const { disabledDays = [] } = dayPickerProps
        const minDay = getMinimumRangeModifier(disabledDays)
        const min = minDay ? formatDay(minDay, getInputFormat(this.props.showTimePicker)) : null
        const maxDay = getMaximunRangeModifier(disabledDays)
        const max = maxDay ? formatDay(minDay, getInputFormat(this.props.showTimePicker)) : null

        if (this.props.value) {
            day = parseMoment(this.props.value)
        }

        return (
            <DateTimeInputWrapper>
                <DateTimeInput
                  ref={(c) => (this.input = c)}
                  id={`${this.dateTimeId}_mobile`}
                  type={getInputType(this.props.showTimePicker)}
                  onChange={this.onChange}
                  value={formatDay(day, getInputFormat(this.props.showTimePicker))}
                  min={min}
                  max={max}
                />
                <DateTimeLabel htmlFor={`${this.dateTimeId}_mobile`}>
                    {this.renderDay(day)}
                </DateTimeLabel>
            </DateTimeInputWrapper>
        )
    }
}

export default MobileDateTimeSelect
