import moment from 'moment-timezone'

import type { ModifierRange } from 'commons/js/services/dateCalculator'

export function applyModifier (day: Date, modifier: ?Date | ?ModifierRange, scale: string): boolean {
    const m = moment(day)

    if (!modifier) {
        return false
    }

    if (modifier instanceof Date) {
        return m.isSame(moment(modifier), scale)
    }

    if (modifier.from && modifier.to) {
        // NOTE: from and to are inclusive
        return m.isBetween(moment(modifier.from), moment(modifier.to), scale, '[]')
    }

    if (modifier.before && modifier.after) {
        return m.isBefore(moment(modifier.before), scale) && m.isAfter(moment(modifier.after), scale)
    }

    if (modifier.before) {
        return m.isBefore(moment(modifier.before), scale)
    }

    if (modifier.after) {
        return m.isAfter(moment(modifier.after), scale)
    }

    return false
}
