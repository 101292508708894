import moment from 'moment-timezone'
import { parseMoment, toDate, makeHourTimeSpan, makeMinuteTimeSpan } from 'commons/js/util/dateConverter'
import { MINUTES_STEP } from 'commons/js/constants'

import type Moment from 'moment'

const MINUTES_IN_A_QUARTER_OF_HOUR = 15
export const DEFAULT_TIME_SPAN = makeHourTimeSpan(4)
export const DEFAULT_DAYS_MIN_TIME_SPAN = 0
export const DEFAULT_HOURS_MIN_TIME_SPAN = makeMinuteTimeSpan(10)

export type ModifierRange = {
    before: ?Date,
    after: ?Date,
}

export function buildBeforeModifier (before: Date): ModifierRange {
    return { before }
}

export function buildAfterModifier (after: Date): ModifierRange {
    return { after }
}

export function calculateToday (step: number): Moment {
    return calculateNextFraction(toDate(), step)
}

export function calculateStartDay (selectedDay, minSelectedDay = calculateToday(MINUTES_STEP), timeSpan = makeMinuteTimeSpan(0)) {
    return moment.max(moment(selectedDay), minSelectedDay).add(timeSpan)
}

export function calculateEndDay (startDay: Moment, endDay: Moment, timeSpan: number = DEFAULT_TIME_SPAN, minTimeSpan: number = DEFAULT_DAYS_MIN_TIME_SPAN): ?Moment {
    if (!startDay) {
        return
    }

    if (!endDay) {
        return parseMoment(startDay).add(timeSpan)
    }

    if (startDay.isBefore(endDay) && endDay.diff(startDay) > minTimeSpan) {
        return endDay
    }

    if (startDay.isSameOrBefore(endDay) && endDay.diff(startDay) <= minTimeSpan) {
        return parseMoment(startDay).add(minTimeSpan)
    }

    return parseMoment(startDay).add(timeSpan)
}

export function calculateMinStartDay (day: Date): Date {
    return toDate(moment.min(parseMoment(day), calculateToday(MINUTES_STEP)))
}

export function calculateMinEndDay (day: Date, timeSpan: number): Date {
    return toDate(parseMoment(day).add(timeSpan))
}

export function calculateNextQuarterFromNow (): Moment {
    const m = parseMoment()
    const timeSpan = makeMinuteTimeSpan(MINUTES_IN_A_QUARTER_OF_HOUR - m.minutes() % MINUTES_IN_A_QUARTER_OF_HOUR)

    return parseMoment().add(timeSpan)
}

export function calculateNextFraction (date: Date, fraction: number): Moment {
    const timeSpan = makeMinuteTimeSpan(fraction - parseMoment(date).minutes() % fraction)

    return parseMoment(date).add(timeSpan)
}
