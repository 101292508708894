import {
    parseMoment,
    toDate,
    makeHourTimeSpan,
    makeMinuteTimeSpan,
} from 'commons/js/util/dateConverter'
import { applyModifier } from './applyModifier'

import { HOURS_STEP, MINUTES_STEP } from 'commons/js/constants'

import type { Moment, Duration } from 'moment-timezone'

export function buildDatesBetween (start: Moment, end: Moment, duration: Duration, acum: Array<Moment> = []): Array<Moment> {
    if (start.isAfter(end)) {
        return acum
    }

    acum.push(start.clone())

    return buildDatesBetween(
        start.clone().add(duration),
        end,
        duration,
        acum,
    )
}

export function buildHours (day: Date, disabledHours, showMeridiem = false): Array<Date> {
    const startOfDay = parseMoment(day).startOf('day')
    const endOfDay = parseMoment(day).endOf('day')
    const duration = makeHourTimeSpan(HOURS_STEP)
    const dates = buildDatesBetween(startOfDay, endOfDay, duration)

    const meridiemHours = (date) => {
        if (!showMeridiem) {
            return true
        }

        return isAM(day) ? isAM(date) : isPM(date)
    }

    return dates
        .map((date) => toDate(date))
        .filter((date) => !applyModifier(date, disabledHours, 'hours'))
        .filter(meridiemHours)
}

export function buildMinutes (day: Date, disabledHours): Array<Date> {
    const startOfDay = parseMoment(day).startOf('hour')
    const endOfDay = parseMoment(day).endOf('hour')
    const duration = makeMinuteTimeSpan(MINUTES_STEP)
    const dates = buildDatesBetween(startOfDay, endOfDay, duration)

    return dates
        .map((date) => toDate(date))
        .filter((date) => !applyModifier(date, disabledHours, 'minutes'))
}

export function buildMeridiems (day: Date, disabledHours): Array<Date> {
    return buildHours(day, disabledHours)
        .filter((date) => isSameMeridiemHour(date, day))
}

const isSameMeridiemHour = (date, otherDate) => parseMoment(date).hour() % 12 === parseMoment(otherDate).hour() % 12
const isAM = (day: Date): boolean => parseMoment(day).hour() < 12
const isPM = (day: Date): boolean => !isAM(day)
