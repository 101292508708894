import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'
import 'react-day-picker/lib/style.css'
import { DATETIME_FORMAT, LOCALE_DATE_FORMAT, YEAR_MONTH_FORMAT, MINUTES_STEP } from 'commons/js/constants'
import { parseMoment, toDate, mergeDate, hasMeridiem } from 'commons/js/util/dateConverter'
import { calculateToday } from 'commons/js/services/dateCalculator'
import Overlay from './Overlay'
import Input from './Input'

const formatMonthTitle = (date, locale = 'es') => moment(date).locale(locale).format(YEAR_MONTH_FORMAT)

class DesktopDateTimeSelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.instanceOf(Date),
        format: PropTypes.string,
        dayPickerProps: PropTypes.object,
        timePickerProps: PropTypes.object,
        yearMonthPickerProps: PropTypes.object,
        showTimePicker: PropTypes.bool,
        showYearMonthPicker: PropTypes.bool,
        onChange: PropTypes.func,
        onNext: PropTypes.func,
        showNextButton: PropTypes.bool,
    }

    static defaultProps = {
        format: LOCALE_DATE_FORMAT,
        showNextButton: false,
        dayPickerProps: {},
        timePickerProps: {},
        yearMonthPickerProps: {},
        showTimePicker: true,
        showYearMonthPicker: false,
        onChange: () => {},
        onNext: () => {},
    }

    state = {
        selectedDay: parseDate(
            this.props.value,
            DATETIME_FORMAT,
            this.props.dayPickerProps.locale
        ),
        value: this.props.value,
    }

    handleDayChange = this.handleDayChange.bind(this)
    handleTimeChange = this.handleTimeChange.bind(this)
    handleYearMonthChange = this.handleYearMonthChange.bind(this)
    setSelectedDay = this.setSelectedDay.bind(this)
    setMonth = this.setMonth.bind(this)
    setDayPickerInputRef = this.setDayPickerInputRef.bind(this)
    renderOverlay = this.renderOverlay.bind(this)
    focus = this.focus.bind(this)
    blur = this.blur.bind(this)

    static getDerivedStateFromProps ({ value, dayPickerProps }, state) {
        const selectedDay = parseDate(
            value,
            DATETIME_FORMAT,
            dayPickerProps.locale
        )
        if (value !== state.value) {
            return {
                selectedDay,
                value,
            }
        }
        return null
    }

    handleDayChange (date) {
        const m = parseMoment(date)

        this.setSelectedDay({
            year: m.year(),
            month: m.month(),
            date: m.date(),
        })
    }

    handleTimeChange (date) {
        const m = parseMoment(date)

        this.setSelectedDay({
            hours: m.hours(),
            minutes: m.minutes(),
            seconds: m.seconds(),
        })
    }

    handleYearMonthChange (date) {
        const m = parseMoment(date)

        this.setMonth({
            year: m.year(),
            month: m.month(),
        })
    }

    setMonth (partialDate) {
        this.setState(
            ({ month }) => ({
                month: mergeDate(month || toDate(), partialDate),
            })
        )
    }

    setSelectedDay (partialDate) {
        this.setState(
            ({ selectedDay }) => ({
                selectedDay: mergeDate(selectedDay || toDate(calculateToday(MINUTES_STEP)), partialDate),
            }),
            () => {
                this.props.onChange(this.state.selectedDay)
            }
        )
    }

    setDayPickerInputRef (element) {
        this.dayPickerInput = element
    }

    focus () {
        this.dayPickerInput.getInput().focus()
    }

    blur () {
        this.dayPickerInput.getInput().blur()
    }

    renderOverlay (props) {
        const { showTimePicker, showYearMonthPicker, timePickerProps, yearMonthPickerProps, showNextButton, onNext } = this.props

        const overlayProps = {
            showTimePicker,
            showYearMonthPicker,
            timePickerProps: {
                showMeridiem: hasMeridiem(),
                ...timePickerProps,
                onChange: this.handleTimeChange,
            },
            yearMonthPickerProps: {
                ...yearMonthPickerProps,
                onChange: this.handleYearMonthChange,
            },
            showNextButton,
            onNext,
        }

        return <Overlay {...props} {...overlayProps} />
    }

    render () {
        const { dayPickerProps } = this.props
        const { selectedDay, month } = this.state

        const extendedDayPickerProps = {
            month,
            localeUtils: {
                ...MomentLocaleUtils,
                formatMonthTitle,
            },
            ...dayPickerProps,
        }

        const extendedInputProps = {
            format: this.props.format,
        }

        return (
            <DayPickerInput
              hideOnDayClick={false}
              {...this.props}
              ref={this.setDayPickerInputRef}
              formatDate={formatDate}
              parseDate={parseDate}
              format={DATETIME_FORMAT}
              value={selectedDay}
              keepFocus
              dayPickerProps={extendedDayPickerProps}
              inputProps={extendedInputProps}
              component={Input}
              overlayComponent={this.renderOverlay}
              onDayChange={this.handleDayChange}
            />
        )
    }
}

export default DesktopDateTimeSelect
