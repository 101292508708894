import styled from 'styled-components'

import { themeColor, Selector } from '@elparking/components'

const Picker = styled(Selector)`
    border: 2px solid ${themeColor('lightGray')};
    padding: 0.5em 0;
    min-width: 3.5em;
    text-align: center;
    color: ${themeColor('lightGray')};
`

export default Picker
